import { query } from "../dom-helpers/query";

export default function initCarousel(gallery: HTMLElement, isCenterCarousel: boolean): void {
  const carouselImageContainer = gallery.querySelector(".carousel-inner") as HTMLElement;
  const carouselWrapper = gallery.querySelector(".carousel-container");
  const smallCarousel = gallery.querySelector(".carousel-image-Small");
  const captionsWrapper = gallery.querySelector(".carousel-captions") as HTMLElement;
  const nextButton = gallery.querySelector("#carousel-next-btn") as HTMLElement;
  const prevButton = gallery.querySelector("#carousel-prev-btn") as HTMLElement;
  const allSlides = Array.from(carouselImageContainer.querySelectorAll(".carousel-cell-item"));
  const itemWidths = [];
  let currentStartIndex = 0;

  function translateElement(element: HTMLElement, transition: string, transform: string): void {
    element.style.transition = transition;
    element.style.transform = transform;
  }

  let isRTL;

  const article = query(".Theme-Story") as HTMLElement;
  if (article.dir === "rtl") {
    isRTL = true;
  }

  function getAspectRatioMediaWidth(media: HTMLImageElement | HTMLVideoElement, carouselHeight: number): number {
    let aspectRatio: number;

    if (media instanceof HTMLImageElement) {
      aspectRatio = media.naturalWidth / media.naturalHeight;
    } else if (media instanceof HTMLVideoElement) {
      aspectRatio = media.videoWidth / media.videoHeight;
    }
    return carouselHeight * aspectRatio;
  }

  function setContainerDimensionsForMedia(media: HTMLImageElement | HTMLVideoElement, container: HTMLElement): void {
    const viewportWidth = window.innerWidth;
    const carouselHeight = smallCarousel ? carouselImageContainer.clientHeight - 60 : carouselImageContainer.clientHeight;

    const updateDimensions = (): void => {
      const mediaWidth = getAspectRatioMediaWidth(media, carouselHeight);
      itemWidths[parseInt(container.dataset.index)] = mediaWidth;

      if (viewportWidth < 900 && !smallCarousel) {
        container.style.width = "92vw";
        container.style.height = "100%";
        container.classList.add("active", "carousel-mobile-media");
      } else {
        container.classList.remove("carousel-mobile-media");
        container.style.width = `${mediaWidth}px`;
        container.style.height = `${carouselHeight}px`;
      }
    };

    function onLoad(): void {
      updateDimensions();
      if (isCenterCarousel) {
        centerInitialSlide();
      } else {
        translateElement(carouselImageContainer, "transform 0.5s ease", `translateX(0px)`);
        translateElement(captionsWrapper, "transform 0.5s ease", `translateX(0px)`);
      }
      updateActiveClasses();
    }

    if (media instanceof HTMLImageElement) {
      if (media.complete) {
        onLoad();
      } else {
        media.addEventListener("load", onLoad);
      }
    } else if (media instanceof HTMLVideoElement) {
      if (media.readyState >= 3) {
        onLoad();
      } else {
        media.addEventListener("loadeddata", onLoad);
      }
    }

    updateActiveClasses();
  }

  function centerInitialSlide(): void {
    const viewportWidth = window.innerWidth;
    if (!carouselImageContainer) return;

    if (viewportWidth < 900 && !smallCarousel) {
      translateElement(carouselImageContainer, "none", `translateX(${currentStartIndex * 92}vw)`);
      translateElement(captionsWrapper, "none", `translateX(${currentStartIndex * 92}vw)`);
    } else {
      const offset = gallery.querySelector<HTMLElement>(".carousel-container").offsetWidth / 2 - itemWidths[currentStartIndex] / 2;
      translateElement(carouselImageContainer, "none", `translateX(${isRTL ? -offset : offset}px)`);
      translateElement(
        captionsWrapper,
        "none",
        `translateX(${isRTL ? -(offset + 15 * currentStartIndex) : offset + 15 * currentStartIndex}px)`
      );
    }
  }

  function updateActiveClasses(): void {
    const allCaptions = gallery.querySelectorAll("[data-media-long-captions]");
    const viewportWidth = window.innerWidth;
    const allEmpty = Array.from(allCaptions).every(caption => caption.textContent.trim() === "");

    allSlides.forEach((slide, index) => {
      const caption = allCaptions[index] as HTMLElement;

      if (viewportWidth < 900 && !smallCarousel) {
        caption.classList.add("mobile-caption");
        caption.style.width = `92vw`;
        caption.classList.remove("carousel-center-caption");
      } else {
        if (isCenterCarousel) {
          caption.classList.add("carousel-center-caption");
          caption.classList.remove("mobile-caption");
          caption.style.width = `${itemWidths[index]}px`;
        } else {
          caption.classList.remove("mobile-caption");
          caption.classList.remove("carousel-center-caption");
          caption.style.width = "92vw";
        }
      }
      if (index !== currentStartIndex && !allEmpty) {
        slide.classList.add("non-active-fade-out");
        caption.classList.add("fade-out-caption", "hide-caption");
        caption.classList.remove("active-caption");
      } else {
        slide.classList.add("active");
        slide.classList.remove("non-active-fade-out");
        caption.classList.add("active-caption");
        caption.classList.remove("fade-out-caption", "hide-caption");
      }
    });

    nextButton.style.display = !isRTL
      ? currentStartIndex === allSlides.length - 1
        ? "none"
        : "block"
      : currentStartIndex === 0
        ? "none"
        : "block";

    prevButton.style.display = !isRTL
      ? currentStartIndex === 0
        ? "none"
        : "block"
      : currentStartIndex === allSlides.length - 1
        ? "none"
        : "block";
  }

  function updateCarouselImageDimensions(): void {
    allSlides.forEach((container: HTMLElement, index) => {
      container.dataset.index = String(index);
      const image = container.querySelector("img");
      const video = container.querySelector("video");

      if (video && !video.paused) {
        video.style.visibility = "visible";
      }

      setContainerDimensionsForMedia(image, container);
    });

    currentStartIndex = 0;

    const allItemsWidths = itemWidths.reduce((acc, width) => acc + width, 0);

    const carousel = gallery.querySelector(".MediaGallery_carousel");

    if (itemWidths[currentStartIndex] < carousel.clientWidth - 20 && !isRTL) {
      //remove fade out if current image is same size or bigger than carousel viewport. Give or take 20 px to account for padding/margins
      carousel.classList.add("fade-at-end");
    } else {
      carousel.classList.remove("fade-at-end");
    }

    if (allItemsWidths < window.innerWidth) {
      carousel.classList.add("center-carousel");
      captionsWrapper.style.display = "none";
      prevButton.style.display = "none";
      nextButton.style.display = "none";
      carousel.classList.remove("fade-at-end");
      translateElement(carouselImageContainer, "transform 0.5s ease", `translateX(0px)`);
      allSlides.forEach(slide => {
        slide.classList.add("active");
        captionsWrapper.classList.add("active-caption");
        slide.classList.remove("non-active-fade-out");
        captionsWrapper.classList.remove("non-active-fade-out");
      });
    } else {
      captionsWrapper.style.display = "flex";
      carousel.classList.remove("center-carousel");
      updateActiveClasses();
    }
  }

  function pxToVw(): number {
    const viewportWidth = window.innerWidth;
    return (15 / viewportWidth) * 100;
  }

  function navigateSlide(direction: "next" | "prev"): void {
    const nextIndex = (currentStartIndex + (direction === "next" ? 1 : -1) + itemWidths.length) % itemWidths.length;

    const nextItemWidth = itemWidths[nextIndex];
    const totalPreviousWidth = itemWidths.slice(0, nextIndex).reduce((acc, width) => acc + width + 15, 0);
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 900 && !smallCarousel) {
      translateElement(
        carouselImageContainer,
        "transform 0.5s ease",
        `translateX(${isRTL ? nextIndex * 92 + pxToVw() * nextIndex : -(nextIndex * 92 + pxToVw() * nextIndex)}vw)`
      );

      translateElement(
        captionsWrapper,
        "transform 0.5s ease",
        `translateX(${isRTL ? nextIndex * 92 + pxToVw() * nextIndex : -(nextIndex * 92 + pxToVw() * nextIndex)}vw)`
      );
    } else {
      if (isCenterCarousel) {
        const offset =
          gallery.querySelector<HTMLElement>(".carousel-container").offsetWidth / 2 - nextItemWidth / 2 - totalPreviousWidth;
        translateElement(carouselImageContainer, "transform 0.5s ease", `translateX(${isRTL ? -offset : offset}px)`);
        translateElement(captionsWrapper, "transform 0.5s ease", `translateX(${isRTL ? -offset : offset + 15 * nextIndex}px)`);
      } else {
        translateElement(
          carouselImageContainer,
          "transform 0.5s ease",
          `translateX(${isRTL ? totalPreviousWidth : -totalPreviousWidth}px)`
        );
        translateElement(captionsWrapper, "transform 0.5s ease", `translateX(${isRTL ? nextIndex * 92 : -nextIndex * 92}vw)`);
      }
    }

    currentStartIndex = nextIndex;
    updateActiveClasses();
  }

  nextButton.addEventListener("click", () => navigateSlide(isRTL ? "prev" : "next"));
  prevButton.addEventListener("click", () => navigateSlide(isRTL ? "next" : "prev"));

  function getTouchPos(touchEvent: TouchEvent): { x: number; y: number } {
    return {
      x: touchEvent.touches[0].clientX,
      y: touchEvent.touches[0].clientY,
    };
  }

  let swipeStartCoords = null;
  let swipeStart = -1;

  carouselWrapper.addEventListener(
    "touchstart",
    (event: TouchEvent) => {
      swipeStartCoords = getTouchPos(event);
      swipeStart = swipeStartCoords.x;
    },
    { passive: true }
  );

  carouselWrapper.addEventListener("touchmove", (event: TouchEvent) => {
    const moveCoords = getTouchPos(event);
    const diffX = moveCoords.x - swipeStartCoords.x;
    const diffY = moveCoords.y - swipeStartCoords.y;

    if (Math.abs(diffX) > Math.abs(diffY) && event.cancelable) {
      event.preventDefault();
    }
  });

  carouselWrapper.addEventListener(
    "touchend",
    (event: TouchEvent) => {
      if (swipeStart === -1) return;

      const swipeLength = event.changedTouches[0].clientX - swipeStart;
      const threshold = window.innerWidth * 0.1;

      if (swipeLength < -threshold && currentStartIndex < itemWidths.length - 1) {
        navigateSlide("next");
      } else if (swipeLength > threshold && currentStartIndex > 0) {
        navigateSlide("prev");
      }

      swipeStart = -1;
    },
    { passive: true }
  );

  async function initializeCarousel(): Promise<void> {
    const mediaArray = allSlides.map(slide => {
      const img = slide.querySelector("img");
      const video = slide.querySelector("video");
      return { img, video };
    });

    await Promise.all(
      mediaArray.map(({ img, video }) => {
        return new Promise<void>(resolve => {
          function checkMediaLoaded(): void {
            if (img) {
              if (img.complete) {
                if (img.naturalWidth > 0) {
                  resolve();
                } else {
                  setTimeout(checkMediaLoaded, 100);
                }
              } else {
                img.addEventListener("load", () => resolve());
                img.src = img.src; // Trigger load event for cached images
              }
            } else if (video) {
              if (video.readyState >= 3) {
                if (video.videoWidth > 0) {
                  resolve();
                } else {
                  setTimeout(checkMediaLoaded, 100);
                }
              } else {
                video.addEventListener("loadeddata", () => resolve());
                video.src = video.src;
              }
            }
          }
          checkMediaLoaded();
        });
      })
    );

    updateCarouselImageDimensions();
  }

  initializeCarousel();

  let previousHeight = window.innerHeight;

  window.addEventListener("resize", () => {
    const currentHeight = window.innerHeight;

    //On a mobile device, the browser's navigation bar natively pops up and down during scrolling/stop scrolling which caused the resize updateCarouselImageDimensions() to be triggered far too often and causes the carousel to scroll back to the start. This check ensures the resize is only triggered if the height change is significant (e.g., more than 100 pixels) and is a "genuine" change in the window's size.
    if (Math.abs(currentHeight - previousHeight) > 100) {
      updateCarouselImageDimensions();
      previousHeight = currentHeight;
    }
  });
}
